<template>
    <div :class="`logo-container logo-container--${logo}`">
        <Component :is="logoComponent" />
        <span class="tagline">Powered by BrightSprout</span>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'LogoLoader',
    props: {
        logo: {
            type: String,
            required: true,
        },
    },
    computed: {
        logoComponent() {
            const formattedLogoName = `${this.logo.charAt(0).toUpperCase()}${this.logo.slice(1)}`
            return () => import(`./${formattedLogoName}Logo.vue`)
        },
    },
})
</script>

<style lang="scss" scoped>
.logo-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 250px;

    &--bingo {
        padding-bottom: 0.75rem;
    }

    &--flashcards {
        padding-bottom: 0.5rem;
    }

    &--worksheets {
        padding-bottom: 0.25rem;
    }
}

.tagline {
    position: absolute;
    right: 0;
    bottom: 0;
    font-size: 10px;
    text-align: right;
    font-style: italic;
    line-height: 1;
}
</style>
